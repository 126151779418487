import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {ProductService} from "@services/products.service";

@Component({
  selector: 'app-video-frame',
  templateUrl: './video-frame.component.html',
  styleUrls: ['./video-frame.component.scss']
})
export class VideoFrameComponent implements OnInit {
  videoUrl: SafeResourceUrl = '';
  email: string;
  lessonName: string;
  productId: number;

  constructor(private sanitizer: DomSanitizer, private route: ActivatedRoute, private productService: ProductService) {
  }

  ngOnInit(): void {
    this.log('Dati del componente video-frame:');

    this.route.queryParams.subscribe(async params => {
      this.email = params['email'];
      this.lessonName = params['product_name'];
      this.productId = params['product_id'];

      this.log('email:', this.email);
      this.log('lessonName:', this.lessonName);
      this.log('videoId:', this.productId);

      if (this.productId) {
        try {
          const result = await this.productService.getMyProductMinimalUnauthorized(this.email, this.productId);
          const clipId = result.vimeoLink.split('/').pop();
          const url = `https://player.vimeo.com/video/${clipId}`;
          this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
          this.log('Sanitized videoUrl', this.videoUrl as string);
        } catch (error) {
          console.error('Errore nel recupero del prodotto:', error);
        }
      } else {
        console.error('Non è stato trovato nessun product_id');
      }
    });
  }

  /**
   * Logs messages to the console only if the current hostname is 'localhost'.
   *
   * @param {...any} args - The messages or objects to be logged to the console.
   * @return {void} No return value.
   */
  private log(...args: any[]): void {
    if (window.location.hostname === 'localhost') {
      console.log(...args);
    }
  }
}
